body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
.popover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.6);
}
.menu-outer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    color: #fff;
}
.menu-outer ul {
    list-style: none;
    text-align: left;
}
.menu-outer ul li {
    padding: 10px 0;
    font-size: 24px;
    cursor: pointer;
    font-family: Spicy Rice, cursive;
}
.menu-outer ul li:hover {
    color: #00b5cc;
}
.close {
    font-size: 36px;
    cursor: pointer;
}
.close {
    position: relative;
    width: 32px;
    height: 32px;
    opacity: 0.3;
    margin-bottom: 20px;
}
.close:hover {
    opacity: 1;
}
.close:before,
.close:after {
    position: absolute;
    left: 15px;
    content: " ";
    height: 33px;
    width: 2px;
    background-color: #fff;
}
.close:before {
    transform: rotate(45deg);
}
.close:after {
    transform: rotate(-45deg);
}
.menu-wrapper {
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 1;
}
