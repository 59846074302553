.Pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: flex-end;
}
.Pagination-left {
    display: flex;
    background: #ffffff;
    margin: 0 5px;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
}
