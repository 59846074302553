@import url("https://fonts.googleapis.com/css?family=Monoton");
@import url("https://fonts.googleapis.com/css?family=Spicy+Rice");

.App {
    text-align: center;
}

.App-logo {
    height: 48px;
    width: 48px;
    margin-left: 100px;
    animation: spin 1s ease-in;
}

@keyframes spin {
    0% {
        transform: rotateY(180deg);
    }
    50% {
        transform: rotateY(180deg);
    }
    100% {
        transform: rotateY(0deg);
    }
}

.App-topbar {
    padding: 30px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Topbar-right {
    margin-right: 30%;
}

.App-header,
.Works,
.Skills,
.Blogs {
    /* background-image: linear-gradient(
        to right bottom,
        #00b5cc,
        #65c3de,
        #97d2ec,
        #c0e1f6,
        #e4f1fe
    ); */

    background-image: linear-gradient(
        to right,
        #f15946,
        #f15946,
        #f15946,
        #f15946,
        #f15946
    );
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: calc(8px + 2vmin);
    color: white;
    position: relative;
}
.Bg-image {
    position: absolute;
    height: 100vh;
    top: 0;
    bottom: 0;
    right: 20px;
    opacity: 0.7;
    pointer-events: none;
    display: flex;
    align-items: center;
}
.Intro-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
}
.Intro {
    padding-left: 100px;
    text-align: left;
    margin-bottom: 100px;
}
.Name-desc {
    opacity: 0.6;
}
.Name {
    padding: 4px 0;
    font-size: calc(16px + 2vmin);
    text-transform: uppercase;
    font-family: "Monoton", cursive;
}
.Resume-link {
    background-image: linear-gradient(#db0a5b, hsl(337, 91%, 45%));
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    display: inline-flex;
    align-items: center;
    color: #ffffff;
    text-decoration: none;
    overflow: hidden;
    position: relative;
    transition: all 0.3s;
    background-size: 0% 100%;
    padding: 0 15px 0 10px;
}
.Resume-link-wrapper {
    background: #2c3e50;
    display: inline-block;
    margin-top: 20px;
}
.Resume-link-text {
    font-size: calc(6px + 2vmin);
    transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 100ms;
}
.Resume-link:hover {
    background-size: 100% 100%;
}
.Resume-link-icon {
    padding: 5px 5px 0px 0;
}
.Wave {
    position: absolute;
    bottom: -1px;
    width: 100%;
    display: block;
}
.Works,
.Blogs {
    background: #f9c22e;
    text-align: left;
}
.arrows path {
    stroke: #ffffff;
    stroke-dasharray: 1 2;
    fill: transparent;
    stroke-width: 1px;
}
.arrows {
    width: 60px;
    height: 72px;
    position: absolute;
    left: 50%;
    margin-left: -30px;
    bottom: 40px;
}
.arrows text {
    font-size: 10px;
}
.Works-title svg path {
    stroke: #ffffff;
    stroke-dasharray: 1 2;
    fill: transparent;
    stroke-width: 2px;
}
.Works-title {
    width: 140px;
    padding: 0 20px;
    display: inline-block;
    text-align: right;
    text-transform: uppercase;
    position: relative;
    left: 50px;
    font-family: "Spicy Rice", cursive;
}

.Projects-wrapper {
    padding: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    overflow: hidden;
}

.Projects-item-wrapper {
    width: 250px;
    position: relative;
    margin: 0 40px;
    display: flex;
    flex-direction: column;
}

.Projects-item {
    background: #ffffff;
    width: 240px;
    height: 340px;
    padding: 5px;
    color: #000;
    border-radius: 20px 0;
    overflow: hidden;
    display: inline-flex;
    flex-direction: column;
    position: relative;
}

.Projects-item img {
    width: 100%;
    height: 50%;
}
.Projects-item svg {
    bottom: 50%;
}
.Project-title {
    font-size: 16px;
    padding: 20px 10px 10px 5px;
    display: inline-block;
    font-weight: 600;
    opacity: 0.8;
}
.Projects-item-wrapper:nth-child(2) .Projects-item {
    top: 20px;
}
.Projects-item-wrapper:nth-child(2) .Projects-item {
    position: relative;
    top: 120px;
}
.Projects-item-wrapper:nth-child(3) .Projects-item {
    top: 220px;
}
.Project-summary {
    font-size: 12px;
    padding: 0 5px;
    flex: 1;
}
.Project-link {
    font-size: 14px;
    position: relative;
    padding: 0 10px 20px 10px;
    display: inline-block;
    color: #e01a4f;
    font-weight: bold;
}
.Skills {
    /*background: rgb(69, 74, 164);*/
    background: rgb(38, 96, 164);
}
.skillsWave {
    bottom: inherit;
    top: 0;
}
.Skills-chart {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}
.Contact .Works-title {
    padding: 150px 0 0 20px;
}
.Linkedin-icon-wrapper {
    padding: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: inline-block;
    transition: all 300ms ease-in-out;
}
.Linkedin-icon-wrapper:hover {
    box-shadow: 0 0 1px 1px #fff, 0 0 2px 2px #0ff; /* outer cyan */
}
.Contact-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    flex-direction: column;
}
.Linkedin-icon {
    display: block;
    padding-bottom: 10px;
    font-weight: bold;
}
.Contact-linkedin {
    font-size: 14px;
}
.Contact-interested {
    padding: 0;
    font-size: 34px;
    /* color: rgb(81 187 231); */
    font-family: cursive;
    color: #5bc0eb;
}
.Contact-send-mail {
    opacity: 0.7;
}
.Vertical-line {
    width: 1px;
    background: linear-gradient(transparent, #ffffff);
    height: 80px;
}

.Contact-email {
    border: 1px dashed rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    padding: 5px;
    display: flex;
    align-items: center;
}
.Contact-email a {
    font-size: 14px;
    color: #ffffff;
    text-decoration: none;
}
.Flex1 {
    flex: 1;
}
.App-menu-icon {
    cursor: pointer;
}

.Pagination {
    margin-top: 50px;
}

@media only screen and (max-width: 1200px) {
    .Bg-image {
        display: none;
    }
}
